.root {
  @apply py-3;
  @apply flex;
  @apply flex-col;
  @apply items-center;

  @apply cq-3xl:flex-row;
  @apply cq-3xl:items-start;
}

.root .logo {
  @apply w-40;
  @apply cq-3xl:w-auto;
}
