.root {
  --colors-primary-rgb: 58, 0, 202;
  --colors-success-rgb: 12, 198, 56;
  --colors-accent-rgb: 251, 58, 114;
  --colors-common-rgb: 24, 3, 77;
  --colors-yellow-rgb: 219, 223, 91;
  --colors-orange-rgb: 223, 157, 91;
  --colors-green-rgb: 116, 200, 69;
}

.root {
  @apply flex;
  @apply flex-col;
  @apply min-h-screen;
}

.root .container {
  @apply grid;
  @apply px-4;
  @apply py-10;
  @apply w-full;
  @apply mx-auto;
  @apply flex-grow;
  @apply max-w-9xl;
}
