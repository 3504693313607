.root {
  @apply flex;
  @apply gap-2;
  @apply w-full;
  @apply h-full;
  @apply flex-col;
  @apply items-center;
  @apply justify-center;
}

.root .icon {
  @apply w-10;
  @apply h-10;
  @apply text-red-500;
}

.root .message {
  @apply px-6;
  @apply text-sm;
  @apply max-w-xl;
  @apply text-center;
  @apply font-medium;
  @apply text-red-500;
}
