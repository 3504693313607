.root {
  @apply w-4;
  @apply ml-2;
  @apply grid;
  @apply auto-rows-fr;
  @apply grid-flow-row;
}

.root .cell {
  @apply h-4;
  @apply relative;
  @apply data-[color='orange']:bg-[#DF9D5B];
  @apply data-[color='yellow']:bg-[#DBDF5B];
  @apply data-[color='green']:bg-[#74C845];
}

.root .cell .arrow {
  @apply w-6;
  @apply h-6;
  @apply -top-1;
  @apply -left-4;
  @apply absolute;
  @apply text-common;
}
