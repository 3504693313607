.root {
  @apply flex;
  @apply h-10;
  @apply w-full;
  @apply text-base;
  @apply text-white;
  @apply bg-primary;
  @apply rounded-full;
  @apply items-center;
  @apply font-semibold;
  @apply justify-center;
}
