.root {
  @apply flex;
  @apply gap-10;
  @apply flex-col;
}

.root .title {
  @apply text-2xl;
  @apply text-center;
  @apply text-primary;
  @apply font-semibold;

  @apply cq-3xl:text-4xl;
  @apply cq-3xl:text-left;
}

.root .sections {
  @apply grid;
  @apply gap-10;
  @apply cq-3xl:grid-cols-2;
  @apply cq-6xl:grid-cols-12;
}

.root .sections > * {
  @apply cq-3xl:data-[row-span="2"]:row-span-2;
  @apply cq-6xl:data-[col-span="4"]:col-span-4;
  @apply cq-6xl:data-[col-span="6"]:col-span-6;
  @apply cq-6xl:data-[col-span="8"]:col-span-8;
}

.root .sections .mediators,
.root .sections .relations {
  @apply flex;
  @apply gap-8;
  @apply w-full;
  @apply flex-col;

  @apply cq-6xl:grid;
  @apply cq-6xl:grid-cols-2;
}

.root .sections .traits {
  @apply flex;
  @apply gap-10;
  @apply items-center;
  @apply justify-center;
}
