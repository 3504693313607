.root {
  @apply relative;
  @apply text-primary;
  @apply data-[size="xs"]:w-3;
  @apply data-[size="xs"]:h-3;
  @apply data-[size="sm"]:w-6;
  @apply data-[size="sm"]:h-6;
  @apply data-[size="md"]:w-10;
  @apply data-[size="md"]:h-10;
}

.root .svg {
  @apply absolute top-0 left-0 w-full h-full animate-rotate;
}

.root .svg .circle {
  @apply stroke-[6px];
  @apply animate-dash;
  @apply stroke-current;
  @apply fill-transparent;
}
