.root {
  @apply w-20;
  @apply h-12;
  @apply text-xs;
  @apply border-2;
  @apply bg-gray-50;
  @apply flex-shrink;
  @apply rounded-full;
  @apply text-primary;
  @apply font-semibold;
  @apply transition-all;
  @apply border-primary/20;

  @apply data-[selected=true]:border-primary;
  @apply data-[disabled=true]:pointer-events-none;
  @apply data-[disabled=true]:opacity-40;
  @apply data-[disabled=true]:bg-white;

  @apply hover:bg-primary/10;

  @apply cq-3xl:w-32;
}
