.root {
  @apply h-20;
  @apply w-full;
  @apply relative;
  @apply overflow-hidden;
}

.root .marks {
  @apply top-8;
  @apply w-full;
  @apply absolute;
  @apply max-w-xl;
  @apply -right-20;
}
