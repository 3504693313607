.root {
  @apply flex;
  @apply gap-4;
  @apply flex-col;
  @apply items-center;
  @apply cq-3xl:flex-row;
}

.root .title {
  @apply text-sm;
  @apply font-medium;
  @apply text-primary;
}
