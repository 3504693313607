.root {
  @apply w-full;
  @apply h-full;
  @apply relative;
  @apply overflow-hidden;
}

.root .slide {
  @apply h-fit;
  @apply w-full;
}
