.root {
  @apply z-20;
  @apply h-16;
  @apply flex;
  @apply top-0;
  @apply sticky;
  @apply w-full;
  @apply bg-white;
  @apply items-center;
  @apply flex-shrink-0;
  @apply data-[shadow=true]:shadow-md;
  @apply data-[shadow=true]:shadow-common/10;
}

.root .back {
  @apply left-2;
  @apply absolute;
}
