.root {
  @apply flex;
  @apply gap-2;
  @apply w-full;
  @apply flex-col;
}

.root .top {
  @apply flex;
  @apply gap-4;
  @apply w-full;
  @apply items-center;
}

.root .top .title {
}

.root .sessions {
}
