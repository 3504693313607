.root {
  @apply flex;
  @apply gap-10;
  @apply flex-col;
}

.root .buttons {
  @apply flex;
  @apply gap-2;
  @apply justify-center;
}
