.root {
  @apply flex;
  @apply gap-10;
  @apply flex-col;
}

.root .dates {
  @apply flex;
  @apply gap-2;
  @apply justify-center;
}

.root .symptoms {
  @apply flex;
  @apply gap-4;
  @apply w-full;
  @apply justify-center;
}

.root .symptoms .symptom {
  @apply flex;
  @apply gap-2;
  @apply flex-col;
  @apply items-center;
}

.root .symptoms .symptom .bar {
  @apply w-12;
  @apply h-44;
  @apply relative;
  @apply rounded-md;
  @apply bg-gray-100;
  @apply flex-shrink-0;

  @apply before:w-full;
  @apply before:absolute;
  @apply before:bottom-0;
  @apply before:bg-primary;
  @apply before:rounded-md;
  @apply before:content-[''];
  @apply before:transition-all;
  @apply before:h-[var(--height)];
  @apply before:opacity-[var(--opacity)];
}

.root .symptoms .symptom .title {
  @apply w-20;
  @apply text-xs;
  @apply text-center;
  @apply font-medium;
  @apply text-primary;
}
